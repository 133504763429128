.matcha {
  background-color: #485731;
}
.taro {
  background-color: #634C91;
}
.classic {
  background-color: #3C3333;
}
.caramel {
  background-color: #875020;
}
.blossom {
  background-color: #C44191;
}
.ivorySilk {
  background-color: #D2BBA2;
}
.sparkle {
  background-color: #D73663;
}
.chicken {
  background-color: #C0392B;
}
.berrymerry {
  background: #c23119;
  background: linear-gradient(90deg, #c23119 22%, #485731 54%);
}
.thai {
  background-color: #CA8346;
}
.kokocaphe {
  background-color: #756E30;
}
.jamong {
  background-color: #FF4D4D;
}
.general {
  background-color: #222222;
}
.generalItem {
  background-color: #C1A97E;
}
@keyframes rotateInCircle {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(90);
  }
  66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shakeItUp {
  33% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInHeaderBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: #000000;
  }
}
@keyframes fadeOutHeaderBackground {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
.statsTable {
  border-radius: 1.2em;
  background-color: #F3F1E4;
  margin: auto;
  color: #47908A;
  display: flex;
  flex-direction: column;
  position: relative;
}
.statsTable h3 {
  border-radius: 5px;
  text-transform: uppercase;
}
.statsTable .searchBar {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px auto;
  width: 100%;
  max-width: 400px;
}
.statsTable .searchBar input {
  width: 100%;
  padding: 0.5em;
  border: 2px solid #ccc;
  border-radius: 12px;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  outline: none;
}
.statsTable .titleContainer {
  display: flex;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  margin: auto;
}
.statsTable .buttonContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  max-width: 400px;
  min-width: 400px;
}
.statsTable .buttonContainer .iconBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  cursor: pointer;
}
.statsTable .buttonContainer .iconBox:hover {
  opacity: 0.7;
}
.statsTable .totalAmount {
  font-size: 28px;
  margin: 10px;
}
.statsTable .sizeDivision {
  font-size: 16px;
  margin-top: 0px;
}
.statsTable .sizeDivision span {
  font-weight: bold;
}
.statsTable .pieChartContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-bottom: 2em;
}
.statsTable .pieChartContainer > div {
  width: 100%;
  max-width: 896px;
}
.statsTable .statsRow {
  border-radius: 5px;
  padding: 1em;
  margin: auto;
  margin-bottom: 1em;
  display: flex;
  flex-direction: column;
  width: 90%;
  align-items: center;
}
@media screen and (max-width: 640px) {
  .statsTable .statsRow {
    max-width: 80%;
  }
}
.statsTable .statsRow .flavourContainer {
  margin: auto;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.statsTable .statsRow .flavourBreakDown {
  display: flex;
  flex-direction: column;
  text-align: right;
  width: 100%;
  color: #F3F1E4;
}
.statsTable .statsRow .flavourBreakDown span {
  float: right;
  line-height: 2em;
  font-size: 1.2em;
  width: 100%;
  font-weight: bold;
  color: #FFF;
  text-align: right;
}
.statsTable .statsRow .itemValue {
  display: flex;
  font-size: 1.1rem;
  font-family: Futura;
  font-weight: bold;
  color: #485731;
  background-color: #F3F1E4;
  padding: 4px;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  height: 36px;
  max-width: 400px;
  width: 90%;
  margin-top: 1em;
}
.statsTable .statsRow .countCircle {
  font-size: 1.1rem;
  font-family: Futura;
  font-weight: bold;
  width: 1.2em;
  height: 1.2em;
  padding: 15px;
  margin-left: 15px;
  border-radius: 50%;
  background-color: #FFF;
  color: #222222;
  display: flex;
  justify-content: center;
}
.statsTable .statsRow .toppingContainer {
  display: flex;
  text-align: right;
  width: 100%;
  color: #F3F1E4;
  justify-content: flex-start;
  margin: 0;
  padding: 0;
  max-height: 40px;
}
@media screen and (max-width: 640px) {
  .statsTable .statsRow .toppingContainer {
    padding-top: 10px;
    width: 80%;
    zoom: 0.8;
  }
}
.statsTable .statsRow .toppingContainer .toppingName {
  padding-right: 10px;
}
.statsTable .statsRow .toppingContainer img {
  padding-right: 10px;
}
.statsTable .statsRow .toppingContainer .toppingPic {
  width: 30px;
}
.statsTable .statsRow .toppingContainer .toppingRatio {
  display: flex;
  padding: 20px;
  color: #F3F1E4;
  font-size: 0.8rem;
  font-family: Futura;
  font-weight: bold;
  margin: 0;
  justify-content: space-between;
  align-items: center;
}
.statsTable .statsRow .sizeCircle {
  font-size: 1.1rem;
  font-family: Futura;
  font-weight: bold;
  width: 1em;
  height: 1em;
  padding: 15px;
  margin-left: 15px;
  border-radius: 4px;
  background-color: transparent;
  border: solid 2px #FFF;
  color: #F3F1E4;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.statsTable .statsRow .sizeCircle span {
  font-weight: bold;
}
