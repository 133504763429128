.crofflesContainer {
  display: flex;
  width: 100%;
  border-radius: 20px;
}
@media screen and (max-width: 820px) {
  .crofflesContainer {
    flex-direction: column;
  }
}
.crofflesContainer .crofflesColumn {
  border-radius: 20px;
  margin: 8px;
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
  flex-direction: column;
  background-color: #F7F4E7;
  max-height: 90vh;
  width: 30vw;
  align-items: center;
  overflow-x: none;
}
@media screen and (max-width: 820px) {
  .crofflesContainer .crofflesColumn {
    width: 90vw;
  }
}
.crofflesContainer .crofflesColumn h2 {
  color: #47908A;
  text-decoration: underline;
}
.crofflesContainer .crofflesColumn .crofflesList {
  overflow-y: auto;
  overflow-x: none;
}
.crofflesContainer .crofflesColumn .croffleBox {
  font-family: 'Lexend Deca';
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  font-weight: bold;
  margin: 10px;
  max-width: 25vw;
  height: auto;
  border-radius: 20px;
  color: #F7F4E7;
  position: relative;
  text-align: center;
  font-size: 20px;
  min-width: 300px;
  background-color: rgba(71, 144, 138, 0.2);
}
.crofflesContainer .crofflesColumn .croffleBox .actionBtn {
  width: 100%;
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: #47908A;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #F7F4E7;
  cursor: pointer;
}
.crofflesContainer .crofflesColumn .croffleBox .actionBtn:hover {
  opacity: 0.5;
}
.crofflesContainer .crofflesColumn .croffleBox .orderNo {
  position: absolute;
  left: 10px;
  top: 10px;
  background-color: #F7F4E7;
  color: #47908A;
  font-size: 14px;
  border-radius: 6px;
  padding: 3px;
}
.crofflesContainer .crofflesColumn .croffleBox .orderTime {
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: #F7F4E7;
  color: #47908A;
  font-size: 14px;
  border-radius: 6px;
  padding: 3px;
}
.crofflesContainer .crofflesColumn .croffleBox .croffleInfo {
  display: flex;
  flex-direction: column;
  padding: 15px;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.crofflesContainer .crofflesColumn .croffleBox .croffleInfo .packingLabel {
  background-color: #47908A;
  color: #F7F4E7;
  border-radius: 6px;
  margin-top: 20px;
  padding: 2%;
}
.crofflesContainer .crofflesColumn .croffleBox .croffleInfo .dineInCroffle {
  background-color: #00cec9;
  color: #FFF;
  font-size: 1em;
  padding: 2%;
  border-radius: 6px;
  max-width: fit-content;
  margin-bottom: 10px;
}
.crofflesContainer .crofflesColumn .croffleBox .croffleInfo .deliveryCroffle {
  background-color: #d63031;
  color: #FFF;
  font-size: 1em;
  padding: 2%;
  border-radius: 6px;
  max-width: fit-content;
  text-align: center;
  margin-bottom: 10px;
}
.crofflesContainer .crofflesColumn .croffleBox .croffleInfo .croffleLabel {
  color: #F7F4E7;
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  margin-top: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
