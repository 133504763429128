.matcha {
  background-color: #485731;
}
.taro {
  background-color: #634C91;
}
.classic {
  background-color: #3C3333;
}
.caramel {
  background-color: #875020;
}
.blossom {
  background-color: #C44191;
}
.ivorySilk {
  background-color: #D2BBA2;
}
.sparkle {
  background-color: #D73663;
}
.chicken {
  background-color: #C0392B;
}
.berrymerry {
  background: #c23119;
  background: linear-gradient(90deg, #c23119 22%, #485731 54%);
}
.thai {
  background-color: #CA8346;
}
.kokocaphe {
  background-color: #756E30;
}
.jamong {
  background-color: #FF4D4D;
}
.general {
  background-color: #222222;
}
.generalItem {
  background-color: #C1A97E;
}
@keyframes rotateInCircle {
  0% {
    transform: rotate(0deg);
  }
  33% {
    transform: rotate(90);
  }
  66% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes shakeItUp {
  33% {
    transform: rotate(-15deg);
  }
  66% {
    transform: rotate(35deg);
  }
  100% {
    transform: rotate(10deg);
  }
}
@keyframes upDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(15px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeInHeaderBackground {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: #000000;
  }
}
@keyframes fadeOutHeaderBackground {
  0% {
    background-color: #000000;
  }
  100% {
    background-color: rgba(0, 0, 0, 0);
  }
}
.hourwiseContainer {
  margin: auto;
  background-color: #91BCB9;
  position: relative;
  max-width: 1024px;
  width: 100%;
}
.hourwiseContainer .dateInput {
  text-align: center;
  margin: auto;
}
.hourwiseContainer .dateContainer {
  width: 100%;
  display: flex;
  margin: auto;
  text-align: center;
  justify-content: space-around;
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .dateContainer {
    flex-direction: column;
  }
}
.hourwiseContainer .dateContainer .dateInput {
  appearance: none;
  background-color: #FFF;
  outline: none;
  border: none;
  border-color: #47908A;
  border-radius: 6px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  margin: 10px;
  text-align: center;
  max-width: 250px;
  font-family: 'Lexend Exa', sans-serif;
  font-weight: bold;
  color: #47908A;
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .dateContainer .dateInput {
    margin: auto;
    margin-bottom: 1em;
  }
}
.hourwiseContainer .backBtn {
  font-size: 1.3em;
  color: #F3F1E4;
  background: #222222;
  border-radius: 1.2em;
  padding: 1.4em;
  text-transform: uppercase;
  font-weight: bold;
  margin: auto;
  margin-top: 0.5em;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.hourwiseContainer .backBtn:hover {
  opacity: 0.5;
  cursor: pointer;
}
.hourwiseContainer .backBtn .backIcon {
  font-size: 1.5em;
  position: absolute;
  left: 1em;
}
.hourwiseContainer .wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: auto;
  width: 100%;
}
.hourwiseContainer .wrapper .statsTable {
  margin: auto;
  margin-top: 0.5em;
  width: 100%;
}
@media screen and (max-width: 640px) {
  .hourwiseContainer .wrapper .statsTable {
    margin-top: 8px;
    max-width: 95%;
  }
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .wrapper {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.hourwiseContainer .dateDiv {
  width: 100%;
  text-align: center;
  font-size: 1em;
  background-color: #F3F1E4;
  border-radius: 1.2em;
  color: #47908A;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  padding: 2em;
}
.hourwiseContainer .dateDiv div {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
}
.hourwiseContainer .infoDiv {
  width: 100%;
  text-align: center;
  font-size: 1em;
  background-color: #F3F1E4;
  border-radius: 1.2em;
  color: #47908A;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  height: 100%;
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2em;
}
.hourwiseContainer .infoDiv .revenueSplits {
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .infoDiv .revenueSplits {
    flex-direction: column;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
}
.hourwiseContainer .infoDiv .revenueSplits div p {
  text-align: left;
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .infoDiv .revenueSplits div p {
    text-align: center;
  }
}
.hourwiseContainer .infoDiv .customerName {
  font-size: 1.4em;
  text-align: center;
  font-weight: bold;
}
.hourwiseContainer .infoDiv p span {
  font-weight: bold;
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .infoDiv {
    width: 95%;
  }
}
.hourwiseContainer .instructionLabel {
  padding: 5px;
  border-radius: 5px;
  background-color: #48dbfb;
  color: #222222;
  text-transform: uppercase;
  width: auto;
  text-align: center;
  margin-top: 0.5em;
}
.hourwiseContainer .orderCards {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  padding-top: 12em;
}
.hourwiseContainer .searchFilterContainer {
  position: fixed;
  top: 0;
  left: 0;
  background-color: #222222;
}
.hourwiseContainer .actionIcon {
  color: #F3F1E4;
}
.hourwiseContainer .actionIcon:hover {
  opacity: 0.7;
  cursor: pointer;
}
.hourwiseContainer .logoutIcon {
  padding-left: 10px;
  padding-right: 10px;
}
.hourwiseContainer .filterContainer {
  display: flex;
  flex-direction: row;
  width: 100vw;
  margin: auto;
  padding-top: 1.2em;
  justify-content: space-between;
  background-color: #222222;
  margin-bottom: 1.5em;
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .filterContainer {
    justify-content: space-between;
  }
}
.hourwiseContainer .filterContainer .badge {
  min-width: 100px;
  opacity: 0.6;
  margin-right: 1em;
}
@media screen and (max-width: 480px) {
  .hourwiseContainer .filterContainer .badge {
    min-width: 80px;
  }
}
.hourwiseContainer .filterContainer .badge:nth-child(1) {
  margin-left: 1em;
}
.hourwiseContainer .filterContainer .badge:hover {
  opacity: 1;
  cursor: pointer;
}
.hourwiseContainer .filterContainer .activeBadge {
  opacity: 1;
}
.hourwiseContainer .filterContainer .activeBadge:hover {
  opacity: 0.6;
  cursor: pointer;
}
.hourwiseContainer .badge {
  cursor: pointer;
  border-radius: 5px;
  line-height: 40px;
  text-align: center;
  color: #F3F1E4;
  padding: 0.5em;
}
.hourwiseContainer .badge:hover {
  opacity: 0.6;
}
.hourwiseContainer .sourceTitle {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.2em;
}
.hourwiseContainer .orderCount {
  font-size: 1.2em;
}
.hourwiseContainer .unknownOrderCount {
  color: #e67e22;
  font-weight: bold;
}
.hourwiseContainer .money {
  color: #0b5b2c;
  font-size: 1em;
}
.hourwiseContainer .moneyLarge {
  color: #0b5b2c;
  font-size: 2.4em;
  font-weight: 500;
}
.hourwiseContainer .moneyBadge {
  background-color: #0b5b2c;
}
.hourwiseContainer .activeStatusBadge {
  background-color: #f0932b;
}
.hourwiseContainer .completedBadge {
  background-color: #6ab04c;
}
.hourwiseContainer .rejectedBadge {
  background-color: #eb4d4b;
}
.hourwiseContainer .deliveryBadge {
  background-color: #3498db;
}
.hourwiseContainer .pickupBadge {
  background-color: #AC3B61;
}
.hourwiseContainer .takeawayBadge {
  background-color: #e67e22;
}
.hourwiseContainer .searchBarContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  background-color: #222222;
  margin: auto;
  padding-top: 1.2em;
}
@media screen and (max-width: 600px) {
  .hourwiseContainer .searchBarContainer {
    flex-direction: column;
  }
  .hourwiseContainer .searchBarContainer .actionContainer {
    padding-top: 10px;
  }
}
.hourwiseContainer .searchBarContainer .searchBar {
  background-color: rgba(0, 0, 0, 0);
  width: 100%;
  color: #F3F1E4;
  height: 1.2em;
  font-size: 1.1em;
  appearance: none;
  border: none;
  outline: none;
  font-weight: bold;
  margin: auto;
  width: 80%;
}
.hourwiseContainer .orderCard {
  height: 400px;
  overflow: auto;
  text-align: left;
  background-color: #222222;
  color: #F3F1E4;
  display: flex;
  margin: auto;
  margin-bottom: 5px;
  border-radius: 1em;
  flex-direction: column;
  width: 45%;
}
@media screen and (max-width: 800px) {
  .hourwiseContainer .orderCard {
    width: 90%;
    height: auto;
  }
}
.hourwiseContainer .orderCard .cardRow {
  display: flex;
  flex-direction: row;
}
.hourwiseContainer .orderCard .cardRow .cardColFull {
  padding: 1em;
  width: 100%;
}
.hourwiseContainer .orderCard .cardRow .cardCol {
  padding: 1em;
  width: 50%;
}
.hourwiseContainer .orderCard .cardRow .cardCol a {
  color: inherit;
}
.hourwiseContainer .orderCard .cardRow .cardCol .instructionLabel {
  padding: 5px;
  border-radius: 5px;
  background-color: #48dbfb;
  color: #222222;
  text-transform: uppercase;
  width: auto;
  text-align: center;
  margin-top: 0.5em;
}
.hourwiseContainer .orderCard .cardRow .colLabel {
  font-weight: bold;
  text-decoration: underline;
}
